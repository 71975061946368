import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Advanced_User/OpenHAB_3_MQTT_Binding/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB 3 MQTT Binding",
  "path": "/Advanced_User/OpenHAB_3_MQTT_Binding/",
  "dateChanged": "2020-12-30",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_OpenHAB.png",
  "social": "/images/Search/AU_SearchThumb_OpenHAB.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='OpenHAB 3 MQTT Binding' dateChanged='2020-12-30' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/OpenHAB_3_MQTT_Binding/' locationFR='/fr/Advanced_User/OpenHAB_3_MQTT_Binding/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "openhab-3-mqtt-binding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-3-mqtt-binding",
        "aria-label": "openhab 3 mqtt binding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB 3 MQTT Binding`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configuring-the-mqtt-binding"
        }}>{`Configuring the MQTT Binding`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mqtt-broker"
        }}>{`MQTT Broker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mqtt-client"
        }}>{`MQTT Client`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "configuring-the-mqtt-binding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-the-mqtt-binding",
        "aria-label": "configuring the mqtt binding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring the MQTT Binding`}</h2>
    <h2 {...{
      "id": "mqtt-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-broker",
        "aria-label": "mqtt broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Broker`}</h2>
    <p>{`I will start by adding my INSTAR IP camera as MQTT Broker to OpenHAB. The broker is configured as follows:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/078fe/OpenHAB3_Docker_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACNElEQVQoz2WSTW/TQBCG/Weod+2EXqoqtVEr3NRJbK/3Y7z+WieOqxYJWlEkJKCqhARVS1REKg7cOXLjgpBygCP8LeS4SUP7aLS3Z9+Z2dXa7bZpmoZhNCdGum6u74zePT74aJcf7HJyvx6Vk+7hlVOda+12u9VqmXMMw0T6mrG+oc6/D69+Rec/5cVMXszilZIXs/RydnD9ezz5oRlzWq1WHTsHIZ3ToBqpYZ4AIxEPBQ146DPisXBeQV8kBY0yrYldygghjDEAlGU5HI2KolBKQRQJAXEcSxlHkQSIxtV+muW38iK2ljnnSqksy9I0lVJyzsUcQkjQ4PuEUG058FJGCAkhyrIsiiJJEs45AFBKu92uZVn2DZbb8+8mN/5SllIKIQAgDEPHcWzb3trqbNV03J6nmQtWkxljSqkkScQCxpjrut0le3sDf6XtlW3XyVVVDYdDABB3ASE45PthXGlNz80VN/KaXrc9Hsdx3KyqmXl319nZ3rZty+psWp0NtzfQEEKNeZOsI/0hCoFmaRpFETQIQRlzesFunzi9oBfGfRp7IWgY49tYA+M19GAT01zUfyTP6wdLU8ZFFov3R3D5NDg+Pik+/a2u/7D917V8u7BG3sBhwlWWp1n9yIJzysUoYV9fed/euKdH+eBkyl5Og/xQw/9TfxIDk5AAwGLgCARjMifHU+/FlyJ//rnvXvv+gefdk+cX+L7POWeM0YaQUCHjJ6fRs7dFdnjmkzPC1cD/B67v/P35mq22AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/e4706/OpenHAB3_Docker_04.avif 230w", "/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/d1af7/OpenHAB3_Docker_04.avif 460w", "/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/7f308/OpenHAB3_Docker_04.avif 920w", "/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/68819/OpenHAB3_Docker_04.avif 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/a0b58/OpenHAB3_Docker_04.webp 230w", "/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/bc10c/OpenHAB3_Docker_04.webp 460w", "/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/966d8/OpenHAB3_Docker_04.webp 920w", "/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/7d4d8/OpenHAB3_Docker_04.webp 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/81c8e/OpenHAB3_Docker_04.png 230w", "/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/08a84/OpenHAB3_Docker_04.png 460w", "/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/c0255/OpenHAB3_Docker_04.png 920w", "/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/078fe/OpenHAB3_Docker_04.png 934w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ce9d8e53f0ab0c2d4febae92276f4e82/c0255/OpenHAB3_Docker_04.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to the `}<strong parentName="p">{`Things`}</strong>{` settings and click on the blue plus icon to add the MQTT broker:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dbdfe767a9df4d6796246c87c837393a/47aef/OpenHAB3_Docker_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABVUlEQVQoz4XQzW7bMAwAYD9BI4k2mR+ZsiVZkrUkbRGkHYYicNFL9wA7DAP2CNs77DJgQN95cIxsaXPod5FAUSTBom3blFKMMefcGHObuj50vvsvhLDf71NK3p+Hu03sCmvt9mi329VaszHWOX5Na80XauYihmCYEStCnBMqKZlZKTWbzcR7isPw9DA8uZi6lBvn5ZE6AwCLxaIsS3WhMN/+dN9f8Mtv+voy//xTiSsA+Pc81fK+A4A3RcfP/e399d2nNm1dvmHfTxlTeykFEVlrQ4htaxFRCCFPs5Xj5xhzirZtvLOGWUoJAERzIkJEIlotV1rrumY6A8fxir7P6/XaWuu9N8ZIKYloyh5XWrPWta5HzGaKLJfLikgKUaSUNptNCME51zTN1Lksy2qCiHg6jzfECqCsAKCaF4fDMAyPOX9wzmtdv1nVJSFlpdQvWn18/vEXOPpDrwtQWJoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dbdfe767a9df4d6796246c87c837393a/e4706/OpenHAB3_Docker_05.avif 230w", "/en/static/dbdfe767a9df4d6796246c87c837393a/d1af7/OpenHAB3_Docker_05.avif 460w", "/en/static/dbdfe767a9df4d6796246c87c837393a/7f308/OpenHAB3_Docker_05.avif 920w", "/en/static/dbdfe767a9df4d6796246c87c837393a/29832/OpenHAB3_Docker_05.avif 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dbdfe767a9df4d6796246c87c837393a/a0b58/OpenHAB3_Docker_05.webp 230w", "/en/static/dbdfe767a9df4d6796246c87c837393a/bc10c/OpenHAB3_Docker_05.webp 460w", "/en/static/dbdfe767a9df4d6796246c87c837393a/966d8/OpenHAB3_Docker_05.webp 920w", "/en/static/dbdfe767a9df4d6796246c87c837393a/0ca5b/OpenHAB3_Docker_05.webp 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dbdfe767a9df4d6796246c87c837393a/81c8e/OpenHAB3_Docker_05.png 230w", "/en/static/dbdfe767a9df4d6796246c87c837393a/08a84/OpenHAB3_Docker_05.png 460w", "/en/static/dbdfe767a9df4d6796246c87c837393a/c0255/OpenHAB3_Docker_05.png 920w", "/en/static/dbdfe767a9df4d6796246c87c837393a/47aef/OpenHAB3_Docker_05.png 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dbdfe767a9df4d6796246c87c837393a/c0255/OpenHAB3_Docker_05.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the MQTT Binding:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "811px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/65ef7bf07ae43ef84e0e4da2f72036ad/fd28b/OpenHAB3_Docker_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAvElEQVQY03XEy26CQBhA4XkBDdAFZUaZ+W8Co23kMjSidNHEjVt9/2cxkBgTTb+cHPVdFduqlM1EHpjZex9C6Pu+LEtmljfMrESkKAp6YmYmYiJCRECUfwCAWiyX+7o5ncamboQlz621biq3brrT2rxkzCr9zKIoUmkUX0N/G38vdXckGYAOQAPyMP8AFNb2pS63x9VaJ4nK4vhc+b+6Gf2uddgBtjB9jjqggPOJWge7TH9ps9XmJ9Mm+bgDGXYrohIO2VoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/65ef7bf07ae43ef84e0e4da2f72036ad/e4706/OpenHAB3_Docker_06.avif 230w", "/en/static/65ef7bf07ae43ef84e0e4da2f72036ad/d1af7/OpenHAB3_Docker_06.avif 460w", "/en/static/65ef7bf07ae43ef84e0e4da2f72036ad/a7235/OpenHAB3_Docker_06.avif 811w"],
              "sizes": "(max-width: 811px) 100vw, 811px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/65ef7bf07ae43ef84e0e4da2f72036ad/a0b58/OpenHAB3_Docker_06.webp 230w", "/en/static/65ef7bf07ae43ef84e0e4da2f72036ad/bc10c/OpenHAB3_Docker_06.webp 460w", "/en/static/65ef7bf07ae43ef84e0e4da2f72036ad/a63ac/OpenHAB3_Docker_06.webp 811w"],
              "sizes": "(max-width: 811px) 100vw, 811px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/65ef7bf07ae43ef84e0e4da2f72036ad/81c8e/OpenHAB3_Docker_06.png 230w", "/en/static/65ef7bf07ae43ef84e0e4da2f72036ad/08a84/OpenHAB3_Docker_06.png 460w", "/en/static/65ef7bf07ae43ef84e0e4da2f72036ad/fd28b/OpenHAB3_Docker_06.png 811w"],
              "sizes": "(max-width: 811px) 100vw, 811px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/65ef7bf07ae43ef84e0e4da2f72036ad/fd28b/OpenHAB3_Docker_06.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the `}<strong parentName="p">{`MQTT Broker`}</strong>{` preset:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "815px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/34a35dabd49569cdc34ab3fa34336ebd/ef916/OpenHAB3_Docker_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABy0lEQVQoz3WRy47aMBSGs+qi0uAb1yS2E98IMSQhQIYMRBqgVOqiq87Mptu+Q/sA8x7zFl314apcRGlRrU9Hv87xZ1m2k0Ymnk6V/msZY1bZYp0t7lfZMpmrf8b1Bs2EdKbTKIoifTNVSSHTUmalmq/0jd3IwoEQIAgRQgiiOkCEend45E++vc2+/5Q/fo2eXtH7dwjhZnoB9DBxMMYAoTuEehiDPxAwnMCRB0ceGI4BRuB6SgjECJCBAyFMlT7keS5EiLAgfdFvQLADo65zAWNOSC2PAfyy3jydP26jWcJ4yoK5TxvYFW2HWtfNdFweX0qfDzBxCISflvnX8/nl8fFgFxserCj7H7nnFTo+HZ9zyhEm9bXtfLF7eLgvChtbrbSp39JopS/ZGNO88FRLGdlktv/s+hS08jJbVlVlrdXaUMooZYzxNlzDGOc8oL7vj0feZIIHw07eV9WmKJIkVUorZZSsv1ZKVVelpFBKqEtHaeO5LuwPOvnD6Xw4HLbldrst89VaCimlEk2Vsq1dCENBKXMnLhk0srXz3W4/i+MwFGEogiC85dKvb06Z67qA9Jt/zrLj6VRVVZKknAdh0B3RhqbK1m9hnLfyb5aKadxG31TLAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/34a35dabd49569cdc34ab3fa34336ebd/e4706/OpenHAB3_Docker_07.avif 230w", "/en/static/34a35dabd49569cdc34ab3fa34336ebd/d1af7/OpenHAB3_Docker_07.avif 460w", "/en/static/34a35dabd49569cdc34ab3fa34336ebd/82c1e/OpenHAB3_Docker_07.avif 815w"],
              "sizes": "(max-width: 815px) 100vw, 815px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/34a35dabd49569cdc34ab3fa34336ebd/a0b58/OpenHAB3_Docker_07.webp 230w", "/en/static/34a35dabd49569cdc34ab3fa34336ebd/bc10c/OpenHAB3_Docker_07.webp 460w", "/en/static/34a35dabd49569cdc34ab3fa34336ebd/0ea8f/OpenHAB3_Docker_07.webp 815w"],
              "sizes": "(max-width: 815px) 100vw, 815px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/34a35dabd49569cdc34ab3fa34336ebd/81c8e/OpenHAB3_Docker_07.png 230w", "/en/static/34a35dabd49569cdc34ab3fa34336ebd/08a84/OpenHAB3_Docker_07.png 460w", "/en/static/34a35dabd49569cdc34ab3fa34336ebd/ef916/OpenHAB3_Docker_07.png 815w"],
              "sizes": "(max-width: 815px) 100vw, 815px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/34a35dabd49569cdc34ab3fa34336ebd/ef916/OpenHAB3_Docker_07.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Give your Broker a unique ID and label and add all the information from your MQTT broker like IP, port and login:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "797px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f3fa5b47d10d98d3200c6155938ff7cd/43fbc/OpenHAB3_Docker_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "101.73913043478262%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACsUlEQVQ4y32TWUscQRDHF6LrQ2ac6WN25+h7rr3chRB33VWRQB40kojG20DyISL6EDSCWS8MKvnASc+MBx7586Poqa5iqrurShXHicIwSRL/Vp7nMcYajYaUimcKgsB/IsdxSgAAFUZJklLKcgihYRi1250oiuM4CVUYRTHnghL6MAZCWIIQJkkaRTG53SOEKhU2m61arR7HSe65S8sJAoIQKgEAWxMT9XrD83xKGWeCEJqmtV5vKklSQihj/BGcizhO8mQQ6rITIYTneRhjx3GCIGCMVyqV6nNyXbcoe9yyWq1WtzvZ7XWVUlXXxRjbtg0AgBCCF2Tbtk4GALiuK4SglEkp4zj2PA8h/X+MnwEhjDLpZISQaRhj5dGxsXJ5dGR05FW+fkq5PGoYr33fI75bcxGAsGSa5tbO1+Ozq8Oj0939g939w+97B/9hd+/wx8+TtfVt0zRLlmUNpmeXVzeWVzZW17Y+r22urm8vLa/Nf/i0sLj0lPmFj4ufVia7fcuy9FOlaco5t8atl2Rblv1AwLYdjIunUiqUUjHGhRBSKimVUoVVYbbFue8HOUFAqlW3UqnoZAiglKpWqzWbTa6DfEJIUIjk8n3fzZQ3QqfTabfbedkgVGGcJFEYMS4YZboXKaW6t1gW7VS0qjkYO1IqISQAQPe2itNsDKIii2go0YOQz5OnR+0e1/WkkChrEvi2nvZ7/d5Uv93uMCY4lwVCWyEek99LdmaEIuI16o1Wq8UoQxBhjDF6BnQvWFyYbdtvur25d+/7g5n+9Gx/MDPVn8mtZpCtMzuYns2ZmZ2bmGjrMxuGsbXz7fjs6vj098nFzcnFzfDiZnh+PTy/Lj7Ptf11fnU0vDwaXh4eX5xe/lnf/GIahr4wmA0QQgVQ9zy888DMAYBtGsYdlmX9G4y/a2ezDgp05/MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f3fa5b47d10d98d3200c6155938ff7cd/e4706/OpenHAB3_Docker_08.avif 230w", "/en/static/f3fa5b47d10d98d3200c6155938ff7cd/d1af7/OpenHAB3_Docker_08.avif 460w", "/en/static/f3fa5b47d10d98d3200c6155938ff7cd/48116/OpenHAB3_Docker_08.avif 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f3fa5b47d10d98d3200c6155938ff7cd/a0b58/OpenHAB3_Docker_08.webp 230w", "/en/static/f3fa5b47d10d98d3200c6155938ff7cd/bc10c/OpenHAB3_Docker_08.webp 460w", "/en/static/f3fa5b47d10d98d3200c6155938ff7cd/9eee1/OpenHAB3_Docker_08.webp 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f3fa5b47d10d98d3200c6155938ff7cd/81c8e/OpenHAB3_Docker_08.png 230w", "/en/static/f3fa5b47d10d98d3200c6155938ff7cd/08a84/OpenHAB3_Docker_08.png 460w", "/en/static/f3fa5b47d10d98d3200c6155938ff7cd/43fbc/OpenHAB3_Docker_08.png 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f3fa5b47d10d98d3200c6155938ff7cd/43fbc/OpenHAB3_Docker_08.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "796px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/36a53ae212797b9672b383ee2c215888/d48f1/OpenHAB3_Docker_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.39130434782608%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB6klEQVQoz43Qz2/aMBQH8FzbEuw4xYnjXyGJQ1o1VC0MFW2H0R/QEASd1sNUTZV2nLRVpQwo62h36GGn/c1bAO2HClKfPvLhPX31bGtKcooL0IDIMAwI5yAAFsZKBUHgc8YgAH9G8ynGWAih5XT94v2Hxx8/x3ffB6Ppl/H93GA87Q/vbobf/m1m/dH06/Sx23ur53IagKBWqydJp5V0TprtxlHr8PiJo78ah82TZru8uwcA0CCErxvH7y4um6128zRN0l6SdpO02+6cpcu00163d16rHWRhkM93zs6v+uOPn/rXg8lo8jCc3A8nD9eD26ub8VOf+6PB7fQ06ei6riGEJKOcMxMhCEFe1+c21tfW11YihJimqRkGehlv1w/q+5VqvBO7brFY9Gb8VTwv8P0AIaSZpilkcb/yolqpRqUtxjjngnMhhFxFZqfINiOEOOdRFEWlku8HUkrXdSl1MLaWsrCFChY0NxdhpVS5XFYqtG3izIoQYttLEJsY2H7lszf1vQ0AszClzJtVdiXpCulKKS3Lwstqs4A9YkWCQgNphmHE8W4UbRHiMMYpXXActgpxOJdFE83CSoVBEHDG/99mrXy2ZTPGst9GCPmB8nzfeXZRSqWUi7BS4U4ch2HoOPQ5KGVSur/DvwCrmZNCik7kTAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36a53ae212797b9672b383ee2c215888/e4706/OpenHAB3_Docker_09.avif 230w", "/en/static/36a53ae212797b9672b383ee2c215888/d1af7/OpenHAB3_Docker_09.avif 460w", "/en/static/36a53ae212797b9672b383ee2c215888/9b205/OpenHAB3_Docker_09.avif 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/36a53ae212797b9672b383ee2c215888/a0b58/OpenHAB3_Docker_09.webp 230w", "/en/static/36a53ae212797b9672b383ee2c215888/bc10c/OpenHAB3_Docker_09.webp 460w", "/en/static/36a53ae212797b9672b383ee2c215888/7fcbc/OpenHAB3_Docker_09.webp 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36a53ae212797b9672b383ee2c215888/81c8e/OpenHAB3_Docker_09.png 230w", "/en/static/36a53ae212797b9672b383ee2c215888/08a84/OpenHAB3_Docker_09.png 460w", "/en/static/36a53ae212797b9672b383ee2c215888/d48f1/OpenHAB3_Docker_09.png 796w"],
              "sizes": "(max-width: 796px) 100vw, 796px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/36a53ae212797b9672b383ee2c215888/d48f1/OpenHAB3_Docker_09.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then click on `}<strong parentName="p">{`Save`}</strong>{` to add the broker to OpenHAB.`}</p>
    <h2 {...{
      "id": "mqtt-client",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-client",
        "aria-label": "mqtt client permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Client`}</h2>
    <p>{`I now want to add `}<strong parentName="p">{`Thing`}</strong>{` that allows me to switch the `}<strong parentName="p">{`Alarm Areas`}</strong>{` of my camera. For this I will add a `}<strong parentName="p">{`Generic MQTT Thing`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "812px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8d0634997013c892ccb909368cc49850/63ec5/OpenHAB3_Docker_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACE0lEQVQoz2VS23LTMBT0U7mU1pbcOrEt2brbkhLHbSYhTWKHJoW0PBRmYIYHHvgG4K94hv8DOSktdGdnZ8/RWUmjkTdQgvF/wBirqmrWYT6fW2sZ+29kP+YNh1X5CIVSSgopRaFkURSPB7TWQggPQhh2gDuEJ+DoWdR8Jt9/2W8/8Nefp2dbePQchifwETwAoO8HQRDcdUIY+GFeRvXr3ujytL4KEYeB7/r79TC828iLoijLc4zR/eEwBP4RODwAh0/AiwMYHD9MAv/YP3y6D5da397evpxO4zhJknTPFCUp3vNvM0Fxv58VQznbRlEEIfCUUuvL9Wx2UZZaCIlxhjDuNEMII9R5hDHKMM7SJGF2bFfv4zgGAHhSqc1mc3W1Xs4XUkhGGaO8U0fO+AMVFONy3FTXX3q9vgtvhPo4X7ytzy8LvZKqFarhquHSqVAtd51Wdt5RNlwumJgkKQTAuxlU1zc37XK5nC8H2hSqtNpabaw2RmujjSm1KY01xhrrWOpSmwuEewB4K1l8WrQfLhbvJtM3g2o7HL2SZcNEy0XDhLsCE3tyudwZykdx4q7NlVpvNoumPRuPR3V9Pp4IpQjjjpQxLnaeckG50ywnSYqifvdgZVFcb7ertq3rmuQkwxkh1IFQZwgl+b3vSkJykuxORhh3X9UYYyllhDBKmcvuAnRXMuLAKGGE0DRFUdT780l+A0v5df7IC0x9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d0634997013c892ccb909368cc49850/e4706/OpenHAB3_Docker_10.avif 230w", "/en/static/8d0634997013c892ccb909368cc49850/d1af7/OpenHAB3_Docker_10.avif 460w", "/en/static/8d0634997013c892ccb909368cc49850/6e64e/OpenHAB3_Docker_10.avif 812w"],
              "sizes": "(max-width: 812px) 100vw, 812px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8d0634997013c892ccb909368cc49850/a0b58/OpenHAB3_Docker_10.webp 230w", "/en/static/8d0634997013c892ccb909368cc49850/bc10c/OpenHAB3_Docker_10.webp 460w", "/en/static/8d0634997013c892ccb909368cc49850/77913/OpenHAB3_Docker_10.webp 812w"],
              "sizes": "(max-width: 812px) 100vw, 812px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d0634997013c892ccb909368cc49850/81c8e/OpenHAB3_Docker_10.png 230w", "/en/static/8d0634997013c892ccb909368cc49850/08a84/OpenHAB3_Docker_10.png 460w", "/en/static/8d0634997013c892ccb909368cc49850/63ec5/OpenHAB3_Docker_10.png 812w"],
              "sizes": "(max-width: 812px) 100vw, 812px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8d0634997013c892ccb909368cc49850/63ec5/OpenHAB3_Docker_10.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add a unique ID (and a unique label that I forgot in the screenshot), add the MQTT Broker we created in the first step and click on `}<strong parentName="p">{`Create Thing`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "810px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dedbdb821d86ac26458b2dda49b5bf20/d7542/OpenHAB3_Docker_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "96.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAA7CAAAOwgEVKEqAAAACo0lEQVQ4y4VUSZLUMBCcM9HtfWlLtiXvW0+7Fy4sc+VCEFz5AL+AD/AJnsGFvyVRZbvHzTTBIS3JllNZVVl6qFUCncSI4xhJksxIeZ1lGQ6HA47HI8Zx5LlSarXvJR4sy0ZRVuj7HkIIRJGAEBJRFCHTOcbxiKqq+J2UMZI4mb+Lm/1huINt23hwHAd5XqCqasTxpG5SmKAoSgzDHl3boet6Rl03SOc9axApE9Kj7wfeyArmDTRvmhaXy2uczxf0XY8sy6F1dnMwgQ4QkYCzEJZlhaZpWD4REWheFhUTLeEu4dFcSImQIASkUlB5DsuyKIcWqrrC5XJGWZZwXRdBEMD3/RkBPM9frX14vo/I91H6AYoZue/DJYVMWNXIdMY/73YRgiC8qqHET+MEKSZVbSTxWef4VFT4qHN8iCQi25kIKQd5nqOua1ZZFAWoWIZhwDTNuzBMExuGga1lwbTtqSj0kWxxOp+w3+/RdR3G43j1W5qmPJIvr5Axv49jCa0UwiDggpAIVki2GYYBbdvi8fGAtms51MWs8YqQ1lJKnlNhaE05txbCJbTtdsvYbDbPYRnGFPYq9CUNNIZhCJ1lqDKNcudx2KyQzPv2zTvshz1biHxGKhYL/QuT6gRaaRRas0r2Ib2gglC1m7phk1KlScH/QOGSMyIhnjulKEtu/L4bONnUl7cIeaQf74GtFa0IS6ry6cRFUaliUqU00lTxSO1Gc7owXhLuOHz650pIFqFwq7pmD96Yes4lhURpuAeKgJrCXorSDwPePz3xvUdFeVZIjR8jTRIo8uR8q6xBh5P6fOllYvU8D57n8hjyicGc8BBhrCBUzghEwipsx5nGv3D14b2PlmnADiWSrz9x+vYL4/ffEF9+wN6+guO6N6TEsfD8AdVP9otZ/tImAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dedbdb821d86ac26458b2dda49b5bf20/e4706/OpenHAB3_Docker_11.avif 230w", "/en/static/dedbdb821d86ac26458b2dda49b5bf20/d1af7/OpenHAB3_Docker_11.avif 460w", "/en/static/dedbdb821d86ac26458b2dda49b5bf20/7fa80/OpenHAB3_Docker_11.avif 810w"],
              "sizes": "(max-width: 810px) 100vw, 810px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dedbdb821d86ac26458b2dda49b5bf20/a0b58/OpenHAB3_Docker_11.webp 230w", "/en/static/dedbdb821d86ac26458b2dda49b5bf20/bc10c/OpenHAB3_Docker_11.webp 460w", "/en/static/dedbdb821d86ac26458b2dda49b5bf20/385bc/OpenHAB3_Docker_11.webp 810w"],
              "sizes": "(max-width: 810px) 100vw, 810px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dedbdb821d86ac26458b2dda49b5bf20/81c8e/OpenHAB3_Docker_11.png 230w", "/en/static/dedbdb821d86ac26458b2dda49b5bf20/08a84/OpenHAB3_Docker_11.png 460w", "/en/static/dedbdb821d86ac26458b2dda49b5bf20/d7542/OpenHAB3_Docker_11.png 810w"],
              "sizes": "(max-width: 810px) 100vw, 810px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dedbdb821d86ac26458b2dda49b5bf20/d7542/OpenHAB3_Docker_11.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now click on the `}<strong parentName="p">{`Thing`}</strong>{` and switch to the `}<strong parentName="p">{`Channel`}</strong>{` tab to add channels for the 4 alarm areas of your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "809px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/69bf789b7673b0b7c3b4b1f53081a72e/e80ac/OpenHAB3_Docker_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABHElEQVQoz3WQ64rDIBCF8wo13mJ1nNE00YQlhV7DPtK+//9FE8oWth8HGTwznNFmRN+j94ghBF/ByjzP6+32vFyWZSEiRNxcChQIE/kBfaOUllJJKY0xQgjOuZSy6zqtO68VdUprbYxRSgnBheCdLkhVaBhjWndEgSggUqgQ7SeFGCiEalEtYojOeaU0Y6w5HA4O/Lp+Px7PcRhTyrumaRxzTlNKOac8bNaYcp5i31tr27YtyVJKISTnvK69C7iQXLR8QxR2q7xLKVWSGWPGGGvdS0frwLofOI392ZzO1se/7iZjjnuytRYAAfxLDsAPZzetNl1duoJ/c0uDg4/D4AAxTjHNMRH1DuDj8L9rfzl7r1qcxXr51nMsH/YLe+w++tc7R9QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/69bf789b7673b0b7c3b4b1f53081a72e/e4706/OpenHAB3_Docker_12.avif 230w", "/en/static/69bf789b7673b0b7c3b4b1f53081a72e/d1af7/OpenHAB3_Docker_12.avif 460w", "/en/static/69bf789b7673b0b7c3b4b1f53081a72e/4e0db/OpenHAB3_Docker_12.avif 809w"],
              "sizes": "(max-width: 809px) 100vw, 809px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/69bf789b7673b0b7c3b4b1f53081a72e/a0b58/OpenHAB3_Docker_12.webp 230w", "/en/static/69bf789b7673b0b7c3b4b1f53081a72e/bc10c/OpenHAB3_Docker_12.webp 460w", "/en/static/69bf789b7673b0b7c3b4b1f53081a72e/f996b/OpenHAB3_Docker_12.webp 809w"],
              "sizes": "(max-width: 809px) 100vw, 809px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/69bf789b7673b0b7c3b4b1f53081a72e/81c8e/OpenHAB3_Docker_12.png 230w", "/en/static/69bf789b7673b0b7c3b4b1f53081a72e/08a84/OpenHAB3_Docker_12.png 460w", "/en/static/69bf789b7673b0b7c3b4b1f53081a72e/e80ac/OpenHAB3_Docker_12.png 809w"],
              "sizes": "(max-width: 809px) 100vw, 809px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/69bf789b7673b0b7c3b4b1f53081a72e/e80ac/OpenHAB3_Docker_12.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Again, add a unique ID and label and assign an `}<strong parentName="p">{`ON/OFF Switch`}</strong>{` as a template:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "793px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0a3df81445baceccd8d6f76069db75d7/73fd0/OpenHAB3_Docker_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAwElEQVQY042R4W6DMAyEeQY7eFlimwBNC5NgaBR37/9kE6yqyjZpnO7XWZ98souctNUYYmRmeZKqTtM0z3PTND9GIsLMqloA4GuIqpX3nohenuRc6Vy5z1Z572OMAFAgumF4t9tn37+pVnunzbtQRFOqh2FcYQB4NCGi8oCIKIRwhy/ni5nVdQLAg7xz7rs2tqdsi43jpFX61fxviygibnB7+piv18W6rue4XvpfM8sKO0Tr8rLczCzn8/ajo5u/APJ8NXXVqTquAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a3df81445baceccd8d6f76069db75d7/e4706/OpenHAB3_Docker_13.avif 230w", "/en/static/0a3df81445baceccd8d6f76069db75d7/d1af7/OpenHAB3_Docker_13.avif 460w", "/en/static/0a3df81445baceccd8d6f76069db75d7/6bf88/OpenHAB3_Docker_13.avif 793w"],
              "sizes": "(max-width: 793px) 100vw, 793px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0a3df81445baceccd8d6f76069db75d7/a0b58/OpenHAB3_Docker_13.webp 230w", "/en/static/0a3df81445baceccd8d6f76069db75d7/bc10c/OpenHAB3_Docker_13.webp 460w", "/en/static/0a3df81445baceccd8d6f76069db75d7/51ddc/OpenHAB3_Docker_13.webp 793w"],
              "sizes": "(max-width: 793px) 100vw, 793px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a3df81445baceccd8d6f76069db75d7/81c8e/OpenHAB3_Docker_13.png 230w", "/en/static/0a3df81445baceccd8d6f76069db75d7/08a84/OpenHAB3_Docker_13.png 460w", "/en/static/0a3df81445baceccd8d6f76069db75d7/73fd0/OpenHAB3_Docker_13.png 793w"],
              "sizes": "(max-width: 793px) 100vw, 793px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0a3df81445baceccd8d6f76069db75d7/73fd0/OpenHAB3_Docker_13.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This opens up the advanced configuration options. First we need to assign the `}<strong parentName="p">{`State`}</strong>{` and `}<strong parentName="p">{`Command`}</strong>{` topic that we want to switch with the toggle:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AREA 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STATE`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cameras/117/status/alarm/area1/enable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AREA 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`COMMAND`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cameras/117/alarm/area1/enable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AREA 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STATE`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cameras/117/status/alarm/area2/enable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AREA 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`COMMAND`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cameras/117/alarm/area2/enable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AREA 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STATE`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cameras/117/status/alarm/area3/enable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AREA 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`COMMAND`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cameras/117/alarm/area3/enable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AREA 4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STATE`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cameras/117/status/alarm/area4/enable`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AREA 4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`COMMAND`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`cameras/117/alarm/area4/enable`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: that my camera is configured to use the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`cameras`}</code>{` prefix and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`117`}</code>{` client ID.`}</p>
    </blockquote>
    <p>{`The `}<strong parentName="p">{`ON`}</strong>{` value has to be set to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` and the `}<strong parentName="p">{`OFF`}</strong>{` value to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"0"}`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "793px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2f98e00adc9eadc424fd763ed59a81ad/73fd0/OpenHAB3_Docker_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACjUlEQVQ4y3VUi5KbMAzMHxxgE2xZfgfIhTck6eX6///VyuQenbY7wtgehOTVyoe8KLz3l8tFKciyLP8/sizjnAMoVMpWPM/zQ1EUqI3zXkrgnJdPHP82zksp4RRPbV13BrK8ODDGl2V7vD22bRuneRynaZyNsUppxH+YlACAsWmLojjkFFkrpQDAaG2MNtoopRBRUYIKFWrUmHZ2aNQKFDkzxtqmres6hABSCiGFEFV6qmRiNyHkNyilioKR8zTPj7fH+/vPbdvWdQ0hWOusc5aeNDqvjZESdgNQMcRnZGMtRaoqKSRIkII+2CPQCwhCyG/kVQBAzpzz17YZxmkYp2mad+v7YZrmoR+tdcY6Y6zWBlFrbY2x1rq6bijtkrFbG6/X27pe7/f79fZjXbdtu96u9+t2W5dtmddlWZum9Z6OYwz5I2rG2KEomHG+JwzDOHV933X90A/DMHaXru+Hpm4S2sulR9RCyOORak7OjLHEkBGJUTKZSKcDA0hIhyRwXlZVxTlv2/Z8PmdZRs5d1/V9H2M8neoY4imeTjGGEJzzzjrvvXfe04IGRBxHSurl5SXJE9F5p7VOElAJKITkjCeU/E8AUEVI24zzU113fW+dUwpJYDoJTROtxDPx63aSnXNVVS2EdY/MvPN1TbQYYzDFT4V5gnS5b5K2UQiRctkJ43yelmmcm6bR2oBCVB8SRv1t8jW31r6+XkgkRVEAqP2v3vsQgk+gLNQnB6gAUu8AKqQzS7GXirfNueu68/k1kfnVetVHb3y2hhCSWkVIRP3U9jQvy0waijE654IPPlkM0VGlnkuXOiQlF5qmefazEMKklqaaucSxIbo1It0px4/bpeRlyY9pnef5b+df5oaKCTlvY4UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f98e00adc9eadc424fd763ed59a81ad/e4706/OpenHAB3_Docker_14.avif 230w", "/en/static/2f98e00adc9eadc424fd763ed59a81ad/d1af7/OpenHAB3_Docker_14.avif 460w", "/en/static/2f98e00adc9eadc424fd763ed59a81ad/6bf88/OpenHAB3_Docker_14.avif 793w"],
              "sizes": "(max-width: 793px) 100vw, 793px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2f98e00adc9eadc424fd763ed59a81ad/a0b58/OpenHAB3_Docker_14.webp 230w", "/en/static/2f98e00adc9eadc424fd763ed59a81ad/bc10c/OpenHAB3_Docker_14.webp 460w", "/en/static/2f98e00adc9eadc424fd763ed59a81ad/51ddc/OpenHAB3_Docker_14.webp 793w"],
              "sizes": "(max-width: 793px) 100vw, 793px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f98e00adc9eadc424fd763ed59a81ad/81c8e/OpenHAB3_Docker_14.png 230w", "/en/static/2f98e00adc9eadc424fd763ed59a81ad/08a84/OpenHAB3_Docker_14.png 460w", "/en/static/2f98e00adc9eadc424fd763ed59a81ad/73fd0/OpenHAB3_Docker_14.png 793w"],
              "sizes": "(max-width: 793px) 100vw, 793px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2f98e00adc9eadc424fd763ed59a81ad/73fd0/OpenHAB3_Docker_14.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click to save the `}<strong parentName="p">{`Channel`}</strong>{` and then continue by `}<strong parentName="p">{`Linking`}</strong>{` it to an `}<strong parentName="p">{`Item`}</strong>{` so that you can start using it inside the UI:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "807px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dcd7af5ff4e10a0c7db5f35c590a9c3f/d2a60/OpenHAB3_Docker_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABMElEQVQoz11Q7VLCMBDsKwiISe5uL0kLDRVa+XQKOgzqc/ggPr2Wwoju3I/N3e1mkywFVe+D/4Wq5nnetu2+bQ+Hw3w+B3Az75YXUR+jZtZaY4z3vifGGBERQIG5lwIdB+Ccu05BRMYY51w2Ho+lXxDhv0Bn87/JzCJirR0MBpmxNqU0S6mclvGMcIV2D/LhBn0nxsjMnZiI6rquqmq1WtV1vWqaajYDoKpN0xRF0fNetl6vy7IEuuQXcZWqzXa33e6Wy+V9COaSVbwPInobOIQIoOfD4TAj5cX+6f30cTy9HV9ev4r0TPLAAmJ2jomEOqO+iJjORwCj0Siz5FB4YQgUJJ+EjagV4Zho2tCkZj+R7o/wr85i65gulsR8J2JYiKVEaHze+JhD6Xrzbf2IvwGEpkP/u0xCiAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dcd7af5ff4e10a0c7db5f35c590a9c3f/e4706/OpenHAB3_Docker_15.avif 230w", "/en/static/dcd7af5ff4e10a0c7db5f35c590a9c3f/d1af7/OpenHAB3_Docker_15.avif 460w", "/en/static/dcd7af5ff4e10a0c7db5f35c590a9c3f/48f1a/OpenHAB3_Docker_15.avif 807w"],
              "sizes": "(max-width: 807px) 100vw, 807px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dcd7af5ff4e10a0c7db5f35c590a9c3f/a0b58/OpenHAB3_Docker_15.webp 230w", "/en/static/dcd7af5ff4e10a0c7db5f35c590a9c3f/bc10c/OpenHAB3_Docker_15.webp 460w", "/en/static/dcd7af5ff4e10a0c7db5f35c590a9c3f/9ec5a/OpenHAB3_Docker_15.webp 807w"],
              "sizes": "(max-width: 807px) 100vw, 807px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dcd7af5ff4e10a0c7db5f35c590a9c3f/81c8e/OpenHAB3_Docker_15.png 230w", "/en/static/dcd7af5ff4e10a0c7db5f35c590a9c3f/08a84/OpenHAB3_Docker_15.png 460w", "/en/static/dcd7af5ff4e10a0c7db5f35c590a9c3f/d2a60/OpenHAB3_Docker_15.png 807w"],
              "sizes": "(max-width: 807px) 100vw, 807px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dcd7af5ff4e10a0c7db5f35c590a9c3f/d2a60/OpenHAB3_Docker_15.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "806px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1d087f76abbdc5c9c8e61810a2a94ecf/764be/OpenHAB3_Docker_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "109.13043478260872%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAIAAABPIytRAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACMUlEQVQ4y42U207jMBCG8wIU2znYscfH1E6dQ0sKFNgbVssd7/9Au04oilgKfPoVWbZG849nnCw6E62WSpkVWuthGB5npmkyF8hITqqyrKpqs9mgFRjjPM+LosjznHxGnufZ9WazP9y8vr5Ox2OMnVLaGKu1sdYCAGOsTvD/lYIxxkqqGPsYuxg7H0Lb7rwPMXbGmDmWXw5GCJRmjF1dbRa3Z9Di7RKEkAxhvHPm19PT8/PvPy8vMXYAUieMUkYpfdYHdFmWGSKkFSw0LvgQQuBcMFYzxqoVlNJ5cy32VrNMSfR85bSuOWMcQLahDaENbaJxzYeCrXVzZnQdYzcO4+zWaG2kVN6H+/vT7d3dOB7atvU+rPynioZhpJRmCCHvw24XAaSUapHWpmm21joAybkQAt6PAGTjtiGEoiiTbeuacdxLKd+PrXXjuO/7QZ1j1hIgQwhVVWWEEEpZXXNe83rVVUrZsn+5z0WyHUJ7eng83NxobdbmPxWA9D5st74oitm2MX3fd33vnONziV/jnNNap2CS57wqjTa7GGPXLTf0tVJtXKRghBBIYbQiGOUEk6RvWI0nQkKZbRy5asAGqZ0AsXgHgPR5WwieSuJSShBQ1zXGOCMEl5RztZWuLbkpGCzP+1vSeBJCpJTHaXo4nboYg/dSqvU8XVIaT0IIFzAM++Pxbppux3EPAF93a9HcKkKcUqfTKcbepP+H+0na85PEZDRwGAZtLKVsmeSf6F/mv45ekla7/cpBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d087f76abbdc5c9c8e61810a2a94ecf/e4706/OpenHAB3_Docker_16.avif 230w", "/en/static/1d087f76abbdc5c9c8e61810a2a94ecf/d1af7/OpenHAB3_Docker_16.avif 460w", "/en/static/1d087f76abbdc5c9c8e61810a2a94ecf/6292e/OpenHAB3_Docker_16.avif 806w"],
              "sizes": "(max-width: 806px) 100vw, 806px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1d087f76abbdc5c9c8e61810a2a94ecf/a0b58/OpenHAB3_Docker_16.webp 230w", "/en/static/1d087f76abbdc5c9c8e61810a2a94ecf/bc10c/OpenHAB3_Docker_16.webp 460w", "/en/static/1d087f76abbdc5c9c8e61810a2a94ecf/e94a4/OpenHAB3_Docker_16.webp 806w"],
              "sizes": "(max-width: 806px) 100vw, 806px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1d087f76abbdc5c9c8e61810a2a94ecf/81c8e/OpenHAB3_Docker_16.png 230w", "/en/static/1d087f76abbdc5c9c8e61810a2a94ecf/08a84/OpenHAB3_Docker_16.png 460w", "/en/static/1d087f76abbdc5c9c8e61810a2a94ecf/764be/OpenHAB3_Docker_16.png 806w"],
              "sizes": "(max-width: 806px) 100vw, 806px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1d087f76abbdc5c9c8e61810a2a94ecf/764be/OpenHAB3_Docker_16.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Repeat those steps for all 4 areas:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "808px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/373970140a11bbe79729b28a95df48eb/3534c/OpenHAB3_Docker_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABrUlEQVQoz4VSiY7aMBDNJyyIwx7PYecksEm4sjh0mwp6rLb9//9pk4BYWqE+Pcn22M/PM+NgEVqxdxCRLMu89wfvm6ZJkuRu19rM2TKysbMBaK2UEpFuUEprTUTEHFspLDlhYkZENewCEJFWCnoEs9lMpPPm/hAiUg9EFEK6Lj8CiSaTyXg8DpRSix5pmkY9nHPDC1n+SsjKNTKdTkejUQAAVVWtVqu63ldVtV6vsyxj5iiKNpvN7SLmPM/ruh4uuYnzfNk0x4NvttstM5kOaEyXwTC5LrsUAIysPytDo9FTgIhFUX7/9uN0Op9O5zTNjEEifkgkVx4B+SJeLpcvB1+/+P2udi7sDR6KEckWjR6cwZiqrH6+/3p7ez+fv8Zx8l9xuDt3zk+9c57njT8evP/0+hpFMYDBvkcPiDarFJiuYFppo9Efmrb90rbtMl+xyODwiE5Ea92LEZLNYrve7fZ1UZRxnFjnujojDvxXTMTqIgawSRiGURhGInboEBHb6NklpYsLZtf/q1vazKKUvjwbtNEatAaAS4fBmAXLs9gVW+oid0DE+Xz+53v+BqTkatpSKZyAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/373970140a11bbe79729b28a95df48eb/e4706/OpenHAB3_Docker_17.avif 230w", "/en/static/373970140a11bbe79729b28a95df48eb/d1af7/OpenHAB3_Docker_17.avif 460w", "/en/static/373970140a11bbe79729b28a95df48eb/5239c/OpenHAB3_Docker_17.avif 808w"],
              "sizes": "(max-width: 808px) 100vw, 808px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/373970140a11bbe79729b28a95df48eb/a0b58/OpenHAB3_Docker_17.webp 230w", "/en/static/373970140a11bbe79729b28a95df48eb/bc10c/OpenHAB3_Docker_17.webp 460w", "/en/static/373970140a11bbe79729b28a95df48eb/2b269/OpenHAB3_Docker_17.webp 808w"],
              "sizes": "(max-width: 808px) 100vw, 808px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/373970140a11bbe79729b28a95df48eb/81c8e/OpenHAB3_Docker_17.png 230w", "/en/static/373970140a11bbe79729b28a95df48eb/08a84/OpenHAB3_Docker_17.png 460w", "/en/static/373970140a11bbe79729b28a95df48eb/3534c/OpenHAB3_Docker_17.png 808w"],
              "sizes": "(max-width: 808px) 100vw, 808px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/373970140a11bbe79729b28a95df48eb/3534c/OpenHAB3_Docker_17.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now switch to the `}<strong parentName="p">{`Overview`}</strong>{` page and switch to the `}<strong parentName="p">{`Properties`}</strong>{` tab:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "809px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/380a964d93354d961d64220e95f6332e/e80ac/OpenHAB3_Docker_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.652173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAY0lEQVQI15WKMQqEMBRE09vojAHzRWejIMTC4BW8hfe/iKyri62veo8Zh5eUwEh6oAIcL+pb+L2Qz/y7Jwtg983AuiSd1OszaN00Tuo7a1szS/MsKYRgJznnGOMvg9mSUjzXAyHpEHxP6MCgAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/380a964d93354d961d64220e95f6332e/e4706/OpenHAB3_Docker_18.avif 230w", "/en/static/380a964d93354d961d64220e95f6332e/d1af7/OpenHAB3_Docker_18.avif 460w", "/en/static/380a964d93354d961d64220e95f6332e/4e0db/OpenHAB3_Docker_18.avif 809w"],
              "sizes": "(max-width: 809px) 100vw, 809px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/380a964d93354d961d64220e95f6332e/a0b58/OpenHAB3_Docker_18.webp 230w", "/en/static/380a964d93354d961d64220e95f6332e/bc10c/OpenHAB3_Docker_18.webp 460w", "/en/static/380a964d93354d961d64220e95f6332e/f996b/OpenHAB3_Docker_18.webp 809w"],
              "sizes": "(max-width: 809px) 100vw, 809px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/380a964d93354d961d64220e95f6332e/81c8e/OpenHAB3_Docker_18.png 230w", "/en/static/380a964d93354d961d64220e95f6332e/08a84/OpenHAB3_Docker_18.png 460w", "/en/static/380a964d93354d961d64220e95f6332e/e80ac/OpenHAB3_Docker_18.png 809w"],
              "sizes": "(max-width: 809px) 100vw, 809px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/380a964d93354d961d64220e95f6332e/e80ac/OpenHAB3_Docker_18.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Since I assigned the created items to the `}<strong parentName="p">{`Presence`}</strong>{` property, the switches will be listed there:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "804px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ceaff4fd809494f2e7d9cdeb79cd92a0/27b7a/OpenHAB3_Docker_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABy0lEQVQoz33QW28SQRiA4YELU5ZDdxZmF9g5QWe3QqsRWViWZeVMu5peNwYTEZoo9l9o8ZTUiNikiUQTLz38RwNSbaySPJlMvuTNTD4gR6PQFPp4qI+H2uNB6NH9jbWuPTxUj0dK4aYSiQIYkmLVcvb75xs/voivn4Lzt+Dj6Trnb2Lf5rGDPWUjBKAkRStF8OEFeD8Bs0ng7OXi/n+B2QTMT6X9VjwkLeKYXQyevQpMTwLTEzB9HpxN1nn3DJy/jvyK5dgmIni72ci1G0bdM+oeLlt6qfhPaes2c+xb93zEGdyUgSzLKJEgybRrVxo1r+7W8uZ1wTMkpV+laykzK/xuDyUSUFGWMUKYsorjuN4d1/NyO7tbhokpuyqNyZZhNlptpGoQwlVMCLGskm1XHKeay+eFMAihhFJC2eJyQdexMIx2u62q6p8YE1ooWiW7Uirb27lcVhj4Ivvr5awwqm5NRZdjxgjnlHPCOctkCOeY0pQQSSEwY5jSy3TG1NW3IVRlqDrl8JNBZNSPHD0IHy3PUT/+dIiOR9JyuDLqh8cD1GloSny1MFWJJ3d3oN9Rei1lbwX2mim/q/td2Gv+Hiq9Jrzb0YoFbbntn3fZkOdXFOS6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ceaff4fd809494f2e7d9cdeb79cd92a0/e4706/OpenHAB3_Docker_19.avif 230w", "/en/static/ceaff4fd809494f2e7d9cdeb79cd92a0/d1af7/OpenHAB3_Docker_19.avif 460w", "/en/static/ceaff4fd809494f2e7d9cdeb79cd92a0/4ee0d/OpenHAB3_Docker_19.avif 804w"],
              "sizes": "(max-width: 804px) 100vw, 804px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ceaff4fd809494f2e7d9cdeb79cd92a0/a0b58/OpenHAB3_Docker_19.webp 230w", "/en/static/ceaff4fd809494f2e7d9cdeb79cd92a0/bc10c/OpenHAB3_Docker_19.webp 460w", "/en/static/ceaff4fd809494f2e7d9cdeb79cd92a0/95e88/OpenHAB3_Docker_19.webp 804w"],
              "sizes": "(max-width: 804px) 100vw, 804px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ceaff4fd809494f2e7d9cdeb79cd92a0/81c8e/OpenHAB3_Docker_19.png 230w", "/en/static/ceaff4fd809494f2e7d9cdeb79cd92a0/08a84/OpenHAB3_Docker_19.png 460w", "/en/static/ceaff4fd809494f2e7d9cdeb79cd92a0/27b7a/OpenHAB3_Docker_19.png 804w"],
              "sizes": "(max-width: 804px) 100vw, 804px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ceaff4fd809494f2e7d9cdeb79cd92a0/27b7a/OpenHAB3_Docker_19.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      